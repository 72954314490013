<template>
  <div>
    <span v-for="chunk in trimmedBody(body)" :key="chunk">
      <a
        v-if="chunk.includes('][')"
        :href="parseLink(chunk)[0]"
        class="post-link"
        >{{ parseLink(chunk)[1] }}</a
      >
      <span v-else-if="chunk === '...'" class="text-nowrap">
        <span>{{ chunk }}</span>
        <a class="post-link mr-1" href="/#" @click.prevent="$emit('readMore')">
          [Read more]</a
        >
      </span>

      <span v-else>{{ chunk }}</span>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'postBodyTrimmed',
  props: {
    body: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(['chunkBody', 'parseLink', 'trimmedBody'])
  }
}
</script>

<style scoped></style>
