<template>
  <div
    class="mx-auto"
    :class="[blogName === 'HarMegiddo' ? 'w-8/12 pl-4' : 'w-10/12']"
  >
    <h3>
      All posts related to: <b>{{ tag }}</b>
    </h3>
    <article
      v-for="post in tagged"
      :key="post.key"
      class="post-entry p-2 w-full mx-auto flex mx-1 mb-2"
    >
      <router-link
        :to="{ name: 'post', params: { slug: post.slug } }"
        class="post-link"
        >{{ post.headline }}</router-link
      >
      <post-body-trimmed
        :body="post.body"
        class="post-body-trimmed"
        @readMore="$router.push({ name: 'post', params: { slug: post.slug } })"
      ></post-body-trimmed>
      <span class="ml-auto">
        <span :class="{ 'fake-date': post.ftimestamp !== undefined }">
          {{ postDisplayDate(post) }}
        </span>
        |
        <router-link
          :to="{ name: 'post', params: { slug: post.slug } }"
          class="comments-link"
          >Comments({{ post.relatedCount }})</router-link
        >
      </span>
    </article>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PostBodyTrimmed from '@/components/PostBodyTrimmed'
import store from '@/store'

function getPosts(routeTo, next) {
  routeTo.params.tagged = store.getters.getEntriesByTag(routeTo.params.tag)
  next()
}

export default {
  name: 'tag-view',
  props: {
    tagged: {
      type: Array,
      required: true
    },
    tag: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState(['blogName']),
    ...mapGetters(['postDisplayDate', 'chunkBody', 'parseLink'])
  },
  components: {
    PostBodyTrimmed
  },
  beforeRouteEnter(routeTo, routeFrom, next) {
    getPosts(routeTo, next)
  },
  beforeRouteUpdate(routeTo, routeFrom, next) {
    getPosts(routeTo, next)
  }
}
</script>

<style scoped lang="scss">
@import '../assets/scss/posts';
</style>
