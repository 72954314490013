<template>
  <article
    class="mb-2 pr-3"
    :class="[blogName === 'HarMegiddo' ? 'w-8/12 pl-4' : 'w-10/12']"
  >
    <div class="mb-2">
      <span :class="{ 'fake-date': post.ftimestamp !== undefined }">
        {{ postDisplayDate(post) }}
      </span>
      <div class="font-medium text-3xl">{{ post.headline }}</div>
      <post-body :body="post.body"></post-body>
    </div>
    <div>
      <div class="post-related">
        <span class="right comments mr-2">Comments</span>
      </div>
      <PostRelated :related="post.related" />
    </div>
  </article>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import PostRelated from '@/components/PostRelated.vue'
import PostBody from '@/components/PostBody.vue'
import store from '@/store'
import Vue from 'vue'

async function getPost(routeTo, next) {
  let post = {}
  if (routeTo.query.id) {
    post = store.getters.getBranchPostById(routeTo.query.id)
    if (!post) {
      const crossBranchEntry = store.getters.getEpochEntryById(routeTo.query.id)
      if (crossBranchEntry) {
        await store.dispatch('switchBranch', crossBranchEntry)
        post = store.getters.getBranchPostById(routeTo.query.id)
      }
    }
  } else {
    post = store.getters.getBranchEntryBySlug(routeTo.params.slug)
  }

  if (post) {
    if (post.properties['WARPTYPE'].toLowerCase() === 'replacement') {
      await store.dispatch('replacePost', post)
    }
    if (store.state.replacements[post.properties['ID']]) {
      const replacementPost = store.getters.getBranchPostById(
        store.state.replacements[post.properties['ID']]
      )
      if (replacementPost) {
        post = replacementPost
      } else {
        Vue.$log.error(
          `missing replacement post: [${
            store.state.replacements[post.properties['ID']]
          }] for [${post.properties['ID']}]`
        )
      }
    }

    if (routeTo.query.id !== post.properties.ID) {
      routeTo.params.scrollId = routeTo.query.id
    }

    routeTo.params.post = post
    next()
  } else {
    next({ name: '404', params: { resource: 'Post' }, replace: true })
  }
}

export default {
  name: 'post-view',
  props: {
    post: {
      type: Object,
      required: true
    },
    scrollId: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapState(['blogName']),
    ...mapGetters(['postDisplayDate', 'chunkBody', 'parseLink'])
  },
  components: {
    PostRelated,
    PostBody
  },
  async beforeRouteEnter(routeTo, routeFrom, next) {
    await getPost(routeTo, next)
  },
  async beforeRouteUpdate(routeTo, routeFrom, next) {
    await getPost(routeTo, next)
  },
  mounted() {
    if (this.scrollId) {
      const scrollDiv = document.getElementById(this.scrollId).offsetTop
      window.scrollTo({ top: scrollDiv })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/scss/posts';
</style>
