import { render, staticRenderFns } from "./PostBody.vue?vue&type=template&id=8fda5ae4&scoped=true&"
import script from "./PostBody.vue?vue&type=script&lang=js&"
export * from "./PostBody.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fda5ae4",
  null
  
)

export default component.exports