<template>
  <story-posts
    v-if="blogName !== 'one20days'"
    class="mx-auto pr-4"
    :class="[blogName === 'HarMegiddo' ? 'w-8/12 pl-4' : 'w-10/12']"
    :page="page"
  />
  <register-view class="w-full" v-else />
</template>

<script>
import StoryPosts from '@/components/StoryPosts'
import RegisterView from '@/views/RegisterView'

import { mapState } from 'vuex'

function setPage(routeTo, next) {
  routeTo.params.page = parseInt(routeTo.query.page) || 1
  next()
}

export default {
  props: {
    page: {
      type: Number,
      required: true
    }
  },
  components: {
    StoryPosts,
    RegisterView
  },
  computed: {
    ...mapState(['blogName'])
  },
  beforeRouteEnter(routeTo, routeFrom, next) {
    setPage(routeTo, next)
  },
  beforeRouteUpdate(routeTo, routeFrom, next) {
    setPage(routeTo, next)
  }
}
</script>

<style scoped></style>
