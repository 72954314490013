<template>
  <div class="align-middle text-center p-10">
    <div v-if="!isLoggedIn">
      <h1 class="h3 mb-3 font-weight-normal">
        Please enter your new 120days credentials:
      </h1>
      <form
        @submit.prevent="onSubmit"
        class="w-1/2 m-auto mb-3 flex flex-nowrap flex-col"
      >
        <input
          type="text"
          class="border p-2 rounded-t"
          id="inlineFormInputGroupUsername2"
          placeholder="Username"
          v-model="regname"
        />
        <input
          type="password"
          class="border p-2"
          id="inlineFormInputGroupUserPassword"
          placeholder="Password"
          v-model="password"
        />
        <input
          type="password"
          class="border p-2 rounded-b"
          id="inlineFormInputGroupUserConfirmPassword"
          placeholder="Confirm Password"
          v-model="confirm_password"
        />
        <button
          type="submit"
          class="bg-blue-600 text-white rounded py-2 px-3 mt-3"
        >
          Register
        </button>
      </form>
    </div>
    <hr class="my-4" />
    <a
      v-for="(connName, connHost) in getRestOfSuite"
      :key="connName"
      :href="connHost"
      class="block"
      >{{ connName }}</a
    >
    <hr class="my-4" />
  </div>
</template>

<script>
import NProgress from 'nprogress'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword
} from 'firebase/auth'

import { collection, doc, setDoc } from 'firebase/firestore'

import moment from 'moment'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'register-view',
  data() {
    return {
      regname: null,
      password: null,
      confirm_password: null
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'getRestOfSuite', 'getFirebaseEmail']),
    ...mapState(['username', 'fireBaseAuth', 'fireStore'])
  },
  methods: {
    async onSubmit() {
      if (!this.regname || !this.password || !this.confirm_password) {
        this.$toasted.error('Username and password required.', {
          duration: 2000,
          icon: 'error_outline',
          position: 'bottom-right'
        })
      } else if (this.password !== this.confirm_password) {
        this.$toasted.error('Confirm password and password do not match', {
          duration: 2000,
          icon: 'error_outline',
          position: 'bottom-right'
        })
      } else {
        let unsubscribe = function () {}
        try {
          NProgress.start()
          const currentUser = this.fireBaseAuth.currentUser
          if (currentUser) {
            await this.fireBaseAuth.signOut()
            await new Promise((resolve) => setTimeout(resolve, 1500))
          }
          unsubscribe = this.fireBaseAuth.onAuthStateChanged(
            async (user) => {
              if (user) {
                try {
                  unsubscribe()
                  // User is signed in.

                  // without wait Firebase struggles to proceed
                  await new Promise((resolve) => setTimeout(resolve, 1500))

                  const usersDataRef = collection(this.fireStore, 'usersData')
                  const dok = doc(
                    usersDataRef,
                    this.getFirebaseEmail(this.regname)
                  )
                  await setDoc(dok, {
                    start: moment().format('YYYY-MM-DD'),
                    branch: 'T',
                    replacements: {}
                  })

                  await this.$store.dispatch('login', {
                    username: this.regname,
                    password: this.password
                  })
                  NProgress.done()
                  this.$toasted.success(
                    `Registration successful: ${this.regname}`,
                    {
                      duration: 2000,
                      icon: 'play_circle_outline',
                      position: 'bottom-right'
                    }
                  )

                  this.regname = null
                  this.password = null
                } catch (error) {
                  this.$log.error(error)
                  this.$toasted.error(error, {
                    duration: 2000,
                    icon: 'error_outline',
                    position: 'bottom-right'
                  })
                  NProgress.done()
                }
              } else {
                // No user is signed in.
              }
            },
            (error) => {
              this.$log.error(error)
              NProgress.done()
            }
          )

          await createUserWithEmailAndPassword(
            this.fireBaseAuth,
            this.getFirebaseEmail(this.regname),
            this.password
          )
          await signInWithEmailAndPassword(
            this.fireBaseAuth,
            this.getFirebaseEmail(this.regname),
            this.password
          )
        } catch (error) {
          unsubscribe()
          this.$log.error(error)
          this.$toasted.error(error, {
            duration: 2000,
            icon: 'error_outline',
            position: 'bottom-right'
          })
          NProgress.done()
        } finally {
          // NProgress.done()
        }
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
