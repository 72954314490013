<template>
  <div id="related">
    <a
      v-for="(connName, connHost) in getRestOfSuite"
      :key="connName"
      :href="connHost"
      class="w-full text-left m-1 aside-fit"
    >
      {{ connName }}
    </a>
    <a
      class="w-full text-left m-1 aside-fit"
      href="https://regimechangegroup.com"
    >
      RegimeChangeGroup
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'related-links',
  computed: {
    ...mapGetters(['getRestOfSuite'])
  }
}
</script>

<style scoped lang="scss">
@import '../assets/scss/aside';
</style>
