<template>
  <header>
    <router-link
      v-if="blogName !== 'one20days'"
      to="/"
      class="blogName blogNameImg block w-full max-w-full"
    />
    <router-link v-else to="/" class="blogName blogNameTxt pt-12">{{
      blogName
    }}</router-link>
  </header>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'top-section',
  computed: {
    ...mapState(['blogName'])
  }
}
</script>

<style scoped lang="scss"></style>
