<template>
  <div class="post-body overflow-auto">
    <span v-for="chunk in chunkBody(body)" :key="chunk">
      <a
        v-if="chunk.includes('][')"
        :href="parseLink(chunk)[0]"
        class="post-link"
        >{{ parseLink(chunk)[1] }}</a
      >
      <img
        v-else-if="chunk.includes('InlineImages')"
        :src="parseImg(chunk)"
        class="float-left p-2 d-inline-block max-w-full"
      />
      <span v-else>{{ chunk }}</span>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'postBody',
  props: {
    body: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(['chunkBody', 'parseLink', 'parseImg'])
  }
}
</script>

<style scoped></style>
