import Vue from 'vue'
import VueRouter from 'vue-router'

import MainView from '../views/MainView.vue'
import TagView from '../views/TagView.vue'
import PostView from '../views/PostView.vue'
import LoginView from '../views/LoginView.vue'
import ProfileView from '../views/ProfileView.vue'
import BlogView from '../views/BlogView.vue'
import NotFound from '../views/NotFound.vue'
import _ from 'lodash'
import { urlToName } from '@/store/const'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'posts',
    component: MainView,
    props: true
  },
  {
    path: '/post/:slug',
    name: 'post',
    component: PostView,
    props: true
  },
  {
    path: '/tag/:tag',
    name: 'tag',
    component: TagView,
    props: true
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/registration',
    name: 'registration',
    beforeEnter() {
      location.href = _.findKey(urlToName[process.env.VUE_APP_MODE], (o) => {
        return o === 'one20days'
      })
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    beforeEnter(to, from, next) {
      const href = _.findKey(urlToName[process.env.VUE_APP_MODE], (o) => {
        return o === 'one20days'
      })
      if (href !== location.origin) {
        location.href = href + '/#/profile'
      } else {
        next()
      }
    }
  },
  {
    path: '/blog',
    name: 'blog',
    component: BlogView,
    beforeEnter(to, from, next) {
      const href = _.findKey(urlToName[process.env.VUE_APP_MODE], (o) => {
        return o === 'one20days'
      })
      if (href !== location.origin) {
        location.href = href + '/#/blog'
      } else {
        next()
      }
    }
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
    props: true
  },
  {
    path: '*',
    redirect: { name: '404', params: { resource: 'page' } }
  }
]

const router = new VueRouter({
  // Routes are attached when story is synced
})

export { router, routes }
