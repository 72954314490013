<template>
  <div class="align-middle text-center p-10 w-full">
    <div v-if="isLoggedIn">
      <form @submit.prevent="onSubmit" class="w-2/3 m-auto flex mb-3">
        <input
          type="password"
          class="inline-block border rounded-l w-10/12 p-2"
          id="inlineFormInputGroupUserPassword"
          placeholder="To erase your profile Enter your password and hit Delete"
          v-model="password"
        />
        <button
          type="submit"
          class="
            w-2/12
            inline-block
            bg-red-500
            text-white
            font-normal
            p-2
            rounded-r
          "
        >
          DELETE
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import NProgress from 'nprogress'
import {
  reauthenticateWithCredential,
  EmailAuthProvider,
  deleteUser
} from 'firebase/auth'
import { mapGetters, mapState } from 'vuex'
import { collection, doc, deleteDoc } from 'firebase/firestore'

export default {
  name: 'profile-view',
  data() {
    return {
      password: null
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'getRestOfSuite', 'getFirebaseEmail']),
    ...mapState(['username', 'fireBaseAuth', 'fireStore'])
  },
  methods: {
    async onSubmit() {
      if (!this.password) {
        this.$toasted.error('password required.', {
          duration: 2000,
          icon: 'error_outline',
          position: 'bottom-right'
        })
      } else {
        try {
          NProgress.start()
          const currentUser = this.fireBaseAuth.currentUser
          const credential = EmailAuthProvider.credential(
            currentUser.email,
            this.password
          )
          await reauthenticateWithCredential(currentUser, credential)
          await this.$store.dispatch('unsubUserData')

          const usersDataRef = collection(this.fireStore, 'usersData')
          const dok = doc(usersDataRef, currentUser.email)
          await deleteDoc(dok)

          await deleteUser(currentUser)
          await this.$store.dispatch('logout')
        } catch (error) {
          this.$log.error(error)
          this.$toasted.error(error, {
            duration: 2000,
            icon: 'error_outline',
            position: 'bottom-right'
          })
        } finally {
          NProgress.done()
        }
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
