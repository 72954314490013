<template>
  <section>
    <div v-for="entry in related" :key="entry.key">
      <div
        class="related-entry"
        :id="entry.properties.ID"
        :style="{ 'padding-left': nestedPadding(entry.level) }"
      >
        <!-- TODO remove headlines from related -->
        <div class="related-headline py-1">{{ entry.headline }}</div>
        <post-body :body="entry.body" class="py-1" />
        <div class="related-author mr-2 text-right">
          <span>
            <span class="author">{{ entry.properties.AUTHOR }}</span>
            {{ entry.properties.TYPE }} on
            <span :class="{ 'fake-date': entry.ftimestamp !== undefined }">
              {{ postDisplayDate(entry) }}
            </span>
          </span>
        </div>
      </div>
      <PostRelated :related="entry.related" />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import PostBody from '@/components/PostBody'

export default {
  name: 'PostRelated',
  props: {
    related: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters(['postDisplayDate'])
  },
  components: {
    PostBody
  },
  methods: {
    nestedPadding(level) {
      return `${(level - 4) * 2}rem`
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/scss/posts';
</style>
