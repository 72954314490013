<template>
  <a id="ads" href="https://regimechangegroup.com">
    <span class="m-auto aside-fit p-1">{{ ads[counter].headline }}</span>
  </a>
</template>

<script>
import { mapState } from 'vuex'
function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max))
}
export default {
  name: 'ads-section',
  data() {
    return {
      counter: 0
    }
  },
  computed: {
    ...mapState(['ads'])
  },
  created() {
    this.counter = getRandomInt(this.ads.length)
    setInterval(() => {
      this.counter = (this.counter += 1) % this.ads.length
      // this.counter = getRandomInt(this.ads.length + 1)
    }, 5000)
  }
}
</script>

<style scoped lang="scss">
@import '../assets/scss/aside';
</style>
