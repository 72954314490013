import 'nprogress/nprogress.css'
import './assets/scss/tailwind.scss'
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyBygJEaScDMtc4BROramRNkQZaJuCCg7NQ',
  authDomain: 'onehundred20.firebaseapp.com',
  projectId: 'onehundred20',
  storageBucket: 'onehundred20.appspot.com',
  messagingSenderId: '301541142628',
  appId: '1:301541142628:web:f6545c97cf112286772f71',
  measurementId: 'G-FTMF36X22N'
}

const firebaseApp = initializeApp(firebaseConfig)
const fireStore = getFirestore(firebaseApp)
// firebase.analytics()

import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './store'

import VueFriendlyIframe from 'vue-friendly-iframe'
Vue.use(VueFriendlyIframe)

import VueLogger from 'vuejs-logger'
const options = {
  isEnabled: true,
  logLevel: store.getters.isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
}

Vue.use(VueLogger, options)

import Toasted from 'vue-toasted'
Vue.use(Toasted, {
  iconPack: 'material'
})

Vue.config.productionTip = false

store
  .dispatch('filterSite')
  .then(() => {
    return store.dispatch('publishBlog')
  })
  .then(() => {
    return store.commit('SET_FIRESTORE', fireStore)
  })
  .then(() => {
    return store.dispatch('setFireBaseAuth')
  })
  .then(() => {
    new Vue({
      router,
      store,
      render: (h) => h(App)
    }).$mount('#app')
  })
  .catch((err) => {
    Vue.$log.error(err)
  })
