export const urlToName = {
  development: {
    'http://localhost:8080': 'FreedomMarch',
    'http://localhost:8081': 'HarMegiddo',
    'http://localhost:8082': 'WordMadMan',
    'http://localhost:8083': 'one20days'
  },
  production: {
    'https://freedomonthemarch.org': 'FreedomMarch',
    'https://harmegiddo.org': 'HarMegiddo',
    'https://wordofamadman.org': 'WordMadMan',
    'https://120daysofsaddam.net': 'one20days'
  },
  staging: {
    'https://staging.freedomonthemarch.org': 'FreedomMarch',
    'https://staging.harmegiddo.org': 'HarMegiddo',
    'https://staging.wordofamadman.org': 'WordMadMan',
    'https://staging.120daysofsaddam.net': 'one20days'
  }
}
