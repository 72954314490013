<template>
  <nav>
    <div class="divide-x" v-if="blogName === 'one20days'">
      <router-link class="px-2" to="/">registration</router-link>
      <router-link class="px-2" to="/blog">blog</router-link>
    </div>
    <div v-if="isLoggedIn" class="pl-1 ml-auto text-xl">
      <span class="font-semibold">
        {{ username }}
      </span>
      <router-link class="profile-link" :to="{ name: 'profile' }">
        [profile]
      </router-link>
      <a href="" @click.prevent="logout">[log out]</a>
    </div>
    <router-link v-else :to="{ name: 'login' }" class="pl-1 ml-auto text-xl">
      <span>Login</span>
    </router-link>
  </nav>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import NProgress from 'nprogress'

export default {
  name: 'navigation-section',
  computed: {
    ...mapState(['blogName', 'username']),
    ...mapGetters(['isLoggedIn'])
  },
  methods: {
    async logout() {
      NProgress.start()
      await this.$store.dispatch('logout')
      NProgress.done()
    }
  }
}
</script>

<style scoped lang="scss">
.router-link-exact-active {
  @apply text-gray-500 cursor-default;
}
</style>
