<template>
  <div
    class="tag-cloud my-3 flex flex-wrap justify-content-center items-baseline"
  >
    <router-link
      v-for="(tagCount, tag) in tags"
      :key="tag"
      :to="{ name: 'tag', params: { tag: tag } }"
      :style="{ 'font-size': 0.6 + tagCount * 0.1 + 'rem' }"
      class="d-inline-flex px-01"
    >
      <code>{{ tag + ' ' }}</code>
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'tag-cloud',
  computed: {
    ...mapState(['tags'])
  }
}
</script>

<style scoped lang="scss">
@import '../assets/scss/aside';
</style>
