<template>
  <div class="flex mx-1">
    <span class="aside-fit mx-auto p-1">HarMegiddo Side column</span>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
@import '../assets/scss/aside';
</style>
